// import web3 from "../../ethereum/web3";
import * as chains from "./chains";
// import * as ethFunc from "../ethereumFunctions";

// const ERC20 = require("../../ethereum/.deps/npm/@rari-capital/solmate/src/tokens/artifacts/ERC20.json");
// const account = "0xb677263DDab7f3d9C60d7c5168eeD79423E6611A";

export let GÖRLICoins = [
  {
    name: "Wrapped KAVA",
    abbr: "WKAVA",
    address: "0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b",
    balance: 0,
    addedByUser: false,
    marker: false,
  },
  {
    name: "Ethereum",
    abbr: "ETH",
    address: "0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D",
    balance: 0,
    addedByUser: false,
    marker: false,
  },
  {
    name: "USDC",
    abbr: "USDC",
    address: "0xfA9343C3897324496A05fC75abeD6bAC29f8A40f",
    balance: 0,
    addedByUser: false,
    marker: false,
  },
  {
    name: "Tether USD",
    abbr: "USDT",
    address: "0xB44a9B6905aF7c801311e8F4E76932ee959c663C",
    balance: 0,
    addedByUser: false,
    marker: false,
  },
  {
    name: "Dai Stablecoin",
    abbr: "DAI",
    address: "0x765277EebeCA2e31912C9946eAe1021199B39C61",
    balance: 0,
    addedByUser: false,
    marker: false,
  },
];

const COINS = new Map();
// COINS.set(chains.ChainId.MAINNET, MAINNETCoins);
// COINS.set(chains.ChainId.ROPSTEN, ROPSTENCoins);
// COINS.set(chains.ChainId.RINKEBY, RINKEBYCoins);
COINS.set(chains.ChainId.KAVA, GÖRLICoins);
// COINS.set(chains.ChainId.KOVAN, KOVANCoins);
export default COINS;
