<template>
  <div class="app-page-wrapper add-liquidity">
    <BaseDialog :showModal="liqDialogVal.bool">
      <liq-dialog :swapDialNum="symbolButtonIndex"></liq-dialog>
    </BaseDialog>
    <div class="app-card swap-form-card">
      <div class="app-card-header">
        <div class="app-hedaer-title">
          <router-link to="/pool">
            <svg height="24" viewBox="0 96 960 960" width="24">
              <path
                d="M400 948.308 27.692 576 400 203.692l42.539 42.539L112.769 576l329.77 329.769L400 948.308Z"
              />
            </svg>
          </router-link>
          <span> Add Liquidity </span>
        </div>
        <div class="app-header-action">
          <base-gear></base-gear>
        </div>
      </div>
      <div class="app-card-body">
        <div class="swap-form">
          <div class="app-input-suffix">
            <small class="max-btn-wrapper" v-if="displayWalletStatus">
              Balance: {{ liqTokenBalVal[0] }}
              <span class="max-btn" @click="fillInputWithMaxAmt(0)">MAX</span>
            </small>
            <input
              type="number"
              placeholder="0.0"
              step="any"
              min="0"
              name="token0"
              id="token0"
              v-model.trim="$store.state.addLiquidity.liqTokenAmount0"
            />
            <button @click="openDialog(0)">
              {{ liqTokenSymbolVal[0] }}
            </button>
          </div>
          <div class="app-input-suffix">
            <small class="max-btn-wrapper" v-if="displayWalletStatus">
              Balance: {{ liqTokenBalVal[1] }}
              <span class="max-btn" @click="fillInputWithMaxAmt(1)">MAX</span>
            </small>
            <input
              type="number"
              placeholder="0.0"
              step="any"
              min="0"
              name="token1"
              id="token1"
              v-model.trim="$store.state.addLiquidity.liqTokenAmount1"
            />
            <button @click="openDialog(1)">
              {{ liqTokenSymbolVal[1] }}
            </button>
          </div>
        </div>
      </div>
      <div class="app-card-footer">
        <div v-if="!displayWalletStatus">
          <WalletConnect :showOnlyButton="true" />
        </div>
        <div v-else-if="$store.state.addLiquidity.insufficientLiqBal">
          <button
            :disabled="$store.state.addLiquidity.insufficientLiqBal"
            :class="{
              'button-disabled': $store.state.addLiquidity.insufficientLiqBal,
              'swap-button': true,
            }"
          >
            Insufficient Balance
          </button>
        </div>
        <div v-else-if="!addLiqActive">
          <button class="swap-button">Enter Amount</button>
        </div>
        <div v-else class="flex-gap">
          <div v-if="$store.state.tokenApprovalInProcess">
            <button
              :disabled="$store.state.operationUnderProcess"
              :class="{
                'button-disabled': $store.state.operationUnderProcess,
                'swap-button': true,
              }"
              @click="approveLiq()"
            >
              Approve Direct Exchange to use {{ liqTokenSymbolVal[0] }} &
              {{ liqTokenSymbolVal[1] }}
            </button>
          </div>
          <button
            :disabled="
              $store.state.operationUnderProcess ||
              $store.state.tokenApprovalInProcess
            "
            :class="{
              'button-disabled':
                $store.state.operationUnderProcess ||
                $store.state.tokenApprovalInProcess,
              'swap-button': true,
            }"
            @click="addLiquidity()"
          >
            Add Liquidity
          </button>
          <!-- <div v-if="$store.state.addLiquidity.liqAdded"> -->
          <!-- {{ this.$alert("Liquidity Added successfully!", "Success") }} -->
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import swal from "sweetalert";

const LiqDialog = defineAsyncComponent(() =>
  import("../components/Liquidity/LiqDialog.vue")
);

export default {
  components: { LiqDialog },
  data() {
    return {
      addLiqActive: false,
      symbolButtonIndex: null,
      A: this.displayWalletStatus,
    };
  },
  methods: {
    ...mapActions({
      approveLiq: "approveLiq",
      addLiquidity: "addLiquidity",
      checkForBal: "checkMaxLiqBal",
      tokensAreApproved: "tokensAreApproved",
    }),
    //OR ...mapActions(["addLiquidity"]),
    openDialog(num) {
      this.symbolButtonIndex = num;
      this.$store.dispatch("openLiqDialog");
    },
    fillInputWithMaxAmt(num) {
      if (num === 0) {
        this.$store.state.addLiquidity.liqTokenAmount0 =
          this.liqTokenBalVal[num];
      } else {
        this.$store.state.addLiquidity.liqTokenAmount1 =
          this.liqTokenBalVal[num];
      }
    },
  },
  computed: {
    ...mapGetters({
      displayWalletStatus: "displayWalletStatus",
      liqTokenSymbolVal: "getLiqTokenSymbol",
      liqDialogVal: "getLiqDialog",
      liqTokenBalVal: "getLiqTokenBal",
    }),
  },
  watch: {
    "$store.state.addLiquidity.liqTokenAmount0"(newVal) {
      if (
        newVal != null &&
        this.$store.state.addLiquidity.LiqExists
        // Number(this.$store.state.addLiquidity.totalSupply) > 1e-12
      ) {
        if (newVal > 0) {
          this.$store.dispatch("fillLiqTokenAmt", 1);
        }
        this.checkForBal();
      } else {
        this.checkForBal();
        this.tokensAreApproved();
        this.addLiqActive = false;
      }
      if (this.$store.state.addLiquidity.liqTokenAmount0) {
        this.addLiqActive = true;
      } else {
        this.addLiqActive = false;
      }
    },
    "$store.state.addLiquidity.liqTokenAmount1"(newVal) {
      if (
        newVal != null &&
        this.$store.state.addLiquidity.pairLiquidity
        // Number(this.$store.state.addLiquidity.totalSupply) > 1e-12
      ) {
        if (newVal > 0) {
          this.$store.dispatch("fillLiqTokenAmt", 0);
        }
        this.checkForBal();
      } else {
        this.checkForBal();
        this.tokensAreApproved();
        this.addLiqActive = false;
      }
      if (this.$store.state.addLiquidity.liqTokenAmount1) {
        this.addLiqActive = true;
      } else {
        this.addLiqActive = false;
      }
    },
  },
  mounted() {
    if (this.$store.state.addLiquidity.dispPool === false) {
      this.$store.dispatch("displayReservesPool");
    } else {
      this.$store.state.addLiquidity.dispPool = false;
    }
  },
  beforeRouteLeave(_, _2, next) {
    if (this.$store.state.canLeave == true) {
      this.$store.commit("resetAddLiqState");
      next();
    } else {
      next(false);
      swal("Alert", "Please wait for the transaction to end!", "warning");
    }
  },
};
</script>
